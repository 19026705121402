
.table-row{
  cursor: pointer;
}

.table-row .within-15-min {
  background-color: #ffe4cd; /* orange */
}

.table-row .within-1-hour {
  background-color: #eff1ad; /* yellow */
}

.table-row .within-2-hour {
  background-color: #d4edda; /* green */
}

.table-row .missing-timestamp {
  background-color: #deb0fa; /* purple */
}

.table-row .elapsed {
  background-color: #ffcccc; /* Light red */
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); /* Black gradient */
  z-index: 1000; /* Ensure it covers everything */
  display: none; 
}

.overlay.show {
  display: block; 
}

.table {
  width: 100%;
  border-collapse: collapse; 
}

.table th {
  position: sticky;
  top: 0; 
  background-color: white; 
  z-index: 10; 
  border-bottom: 2px solid #dee2e6; 
}

.table th,
.table td {
  padding: 8px; 
  text-align: left; 
}

.table-row a,.table-row a:hover{
  color: inherit; /* Inherit color from the parent */
  text-decoration: none; /* Remove underline */
}


