.card-pressed div{
transform: scale(0.98);
box-shadow: 0 8px 16px #007bff;
border-radius: 8px;
transition: transform 0.2s, box-shadow 0.2s;
}

.card-pressed:hover div{
transform: scale(1);
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.modal-body {
max-height: 80vh;
overflow-y: auto;
padding: 0 1rem 1rem 1rem;
}


ul.list-group {
padding-left: 0;
}

.list-group-item {
display: flex;
justify-content: space-between;
align-items: center;
}

.list-group-item span {
font-size: 1.1em;
}


.card-body{
    cursor: pointer;
}